import Vue from 'vue';
import Rails from '@rails/ujs';
import '../admin/public/bootstrap';
import LoadScript from '@common/components/LoadScript.vue';
import Navigation from '../../javascript/application/navigation';
import OverlayTrigger from '../application/javascript/Overlays/OverlayTrigger.vue';
import GlossaryTrigger from '../application/javascript/Glossary/GlossaryTrigger.vue';
import SideMenuItem from '../application/javascript/SideMenuItem.vue';
import PortalVue from 'portal-vue';
import editorLinks from '../application/javascript/editorLinks';

import AOS from 'aos';
import 'aos/dist/aos.css';

require('../styles/application.scss');

// Add all images from the "images" folder to the pipeline
require.context('../application/images', true);

Rails.start();
require.context('../application/images', true);

// Import all images <%= image_pack_tag 'rails.png' %>
require.context('../application/images', true);

Vue.use(PortalVue);

Vue.component('load-script', LoadScript);
Vue.component('navigation', Navigation);
Vue.component('overlay-trigger', OverlayTrigger);
Vue.component('glossary-trigger', GlossaryTrigger);
Vue.component('side-menu-item', SideMenuItem);
Vue.component('photo-gallery', () => import('../application/javascript/PhotoGallery.vue'));
Vue.component('has-state', () => import('../application/javascript/hasState.vue'));
Vue.component('collapsible', () => import('../application/javascript/Collapsible.vue'));
// Old flourish chart component - remove when data transfer is done
Vue.component('flourish', () => import('../application/javascript/Flourish/FlourishChart.vue'));
Vue.component('chart', () => import('../application/javascript/Flourish/FlourishChart.vue'));
Vue.component('sticky-header', () => import('../application/javascript/StickyHeader.vue'));
Vue.component('email-subscription', () =>
  import('../application/javascript/EmailSubscription.vue')
);
Vue.component('partners-slider', () => import('../application/javascript/PartnersSlider.vue'));
Vue.component('testimonials-slider', () =>
  import('../application/javascript/TestimonialsSlider.vue')
);
Vue.component('featured-stories-slider', () =>
  import('../application/javascript/FeaturedStoriesSlider.vue')
);
Vue.component('business-modelling-page', () =>
  import('../application/javascript/business-modelling/components/Page.vue')
);
Vue.component('resources-browser', () => import('../application/javascript/resources/Browser'));

// Search page
import '../../../app/views/search/vue/index';

// Bootstrap the Vue root instance
window.addEventListener('DOMContentLoaded', function() {
  new Vue({
    el: '#js-wrapper',
    mounted() {
      AOS.init();

      setTimeout(() => {
        editorLinks();
      }, 1000);
    }
  });
});
